import incidentsApi from '@/utils/api/incidents';
import incidentsApiV2 from '@/utils/api-v2/incidents';
import { PARSE_INCIDENTS } from '@/helpers/incidentsHelper';

const getId = (string) => string?.substring(string.lastIndexOf('/') + 1);

export default {
    namespaced: true,
    state: () => ({
        incidents: [],
        totalIncidentsAmount: 0,
        incidentTypes: [],
        totalIncidentTypesAmount: null,
        incidentStatuses: [],
        totalIncidentStatusAmount: null,
        incidentSingleType: null,
        // new
        incidentStatusId: null,
        incidentStatusFormVisibility: false,
        newIncidentsNotification: false,

        visibleShortcuts: []
    }),
    getters: {
        filterableStatusesList: state => state.incidentStatuses.map(el => ({
            value: el.id,
            text: el.name,
            isDefault: el.is_default
        })),
        filterableTypesList: state => state.incidentTypes.map(el => ({
            value: el.id,
            text: el.name
        }))
    },
    mutations: {
        SET_INCIDENTS (state, payload) {
            state.incidents = payload;
        },
        SET_NEW_INCIDENTS (state, payload) {
            const seq = state?.incidents?.[0]?.seq;
            if (seq) {
                state.incidents.splice(-payload.length);
                state.incidents.unshift(...payload);
                state.incidents.map((el, index) => {
                    el.seq = seq + index;
                });
            }
        },
        SET_TOTAL_INCIDENTS_AMOUNT (state, payload) {
            state.totalIncidentsAmount = payload;
        },
        SET_TOTAL_INCIDENT_TYPES_AMOUNT (state, payload) {
            state.totalIncidentTypesAmount = payload;
        },
        SET_INCIDENT_STATUSES (state, payload) {
            state.incidentStatuses = payload;
        },
        SET_INCIDENT_TYPES (state, payload) {
            state.incidentTypes = payload;
        },
        SET_INCIDENT_SINGLE_TYPE (state, payload) {
            state.incidentSingleType = payload;
        },
        SET_TOTAL_INCIDENT_STATUS_AMOUNT (state, payload) {
            state.totalIncidentStatusAmount = payload;
        },
        SET_NEW_INCIDENTS_NOTIFICATION (state, payload) {
            state.newIncidentsNotification = payload;
        },
        SHOW_SHORTCUT (state, payload) {
            state.visibleShortcuts.push(payload);
        },
        HIDE_SHORTCUT (state, id) {
            state.visibleShortcuts = state.visibleShortcuts.filter(shortcut => shortcut.id !== id);
        },
        SET_VISIBLE_SHORTCUTS (state, payload) {
            state.visibleShortcuts = payload;
        }
    },
    actions: {
        getIncidents ({ commit, dispatch }, params) {
            commit('SET_INCIDENTS', []);

            return incidentsApiV2.getIncidents(params)
                .then(({ data }) => {
                    commit('SET_INCIDENTS', PARSE_INCIDENTS(data.results, params));
                    commit('SET_TOTAL_INCIDENTS_AMOUNT', data.count);
                });
        },
        updateIncidents ({ commit, state }, params) {
            return incidentsApiV2.getIncidents(params)
                .then(({ data }) => {
                    const oldIncidents = state.incidents.map(el => el.id);
                    const newIncidents = data.results.filter(el => !oldIncidents.includes(el.id));
                    if (newIncidents.length) {
                        commit('SET_NEW_INCIDENTS', PARSE_INCIDENTS(newIncidents, params));
                        commit('SET_TOTAL_INCIDENTS_AMOUNT', data.count);
                    }
                });
        },
        getIncidentStatuses ({ commit }, additionalParams = null) {
            const params = {
                sort: 'name',
                'stats[total]': 'count'
            };

            if (additionalParams) {
                Object.keys(additionalParams).forEach(k => {
                    params[k] = additionalParams[k];
                });
            }

            return incidentsApi.getStatuses(params)
                .then(({ data }) => {
                    commit('SET_INCIDENT_STATUSES', data.data.map((el, index) => ({
                        seq: (index + 1) + ((params['page[number]'] - 1) * params['page[size]']),
                        id: el.id,
                        ...el.attributes
                    })));

                    if (data.meta.stats) {
                        commit('SET_TOTAL_INCIDENT_STATUS_AMOUNT', data.meta.stats.total.count);
                    }
                });
        },
        getIncidentStatusesLazyLoading ({ state, commit }, additionalParams = null) {
            const params = {
                sort: 'name',
                'stats[total]': 'count'
            };

            if (additionalParams) {
                Object.keys(additionalParams).forEach(k => {
                    params[k] = additionalParams[k];
                });
            }

            return incidentsApi.getStatuses(params)
                .then(({ data }) => {
                    const result = [...state.incidentStatuses].concat(data.data.map(el => ({
                        id: el.id,
                        ...el.attributes
                    })));
                    commit('SET_INCIDENT_STATUSES', result);

                    if (data.meta.stats) {
                        commit('SET_TOTAL_INCIDENT_STATUS_AMOUNT', data.meta.stats.total.count);
                    }
                });
        },
        getIncidentTypes ({ commit }, additionalParams = null) {
            const params = {
                'stats[total]': 'count',
                sort: 'name'
            };

            if (additionalParams) {
                Object.keys(additionalParams).forEach(k => {
                    params[k] = additionalParams[k];
                });
            }

            return incidentsApi.getTypes(params)
                .then(({ data }) => {
                    commit('SET_INCIDENT_TYPES', data.data.map((el, index) => {
                        const _seq = (index + 1) + ((params['page[number]'] - 1) * params['page[size]']);
                        return {
                            seq: _seq,
                            id: el.id,
                            ...el.attributes,
                            templateId: getId(el.relationships.template.links.related)
                        };
                    }));

                    if (data.meta.stats) {
                        commit('SET_TOTAL_INCIDENT_TYPES_AMOUNT', data.meta.stats.total.count);
                    }
                });
        },
        getIncidentTypesLazyLoading ({ state, commit }, additionalParams = null) {
            const params = {
                'stats[total]': 'count',
                sort: 'name'
            };

            if (additionalParams) {
                Object.keys(additionalParams).forEach(k => {
                    params[k] = additionalParams[k];
                });
            }

            return incidentsApi.getTypes(params)
                .then(({ data }) => {
                    const result = [...state.incidentTypes].concat(data.data.map(el => ({
                        id: el.id,
                        ...el.attributes,
                        templateId: getId(el.relationships.template.links.related)
                    })));

                    commit('SET_INCIDENT_TYPES', result);

                    if (data.meta.stats) {
                        commit('SET_TOTAL_INCIDENT_TYPES_AMOUNT', data.meta.stats.total.count);
                    }
                });
        },
        getSingleIncidentType ({ commit }, id) {
            return incidentsApi.getSingleType(id)
                .then(({ data }) => {
                    commit('SET_INCIDENT_SINGLE_TYPE', {
                        id: data.data.id,
                        ...data.data.attributes,
                        templateId: getId(data.data.relationships.template.links.related)
                    });
                });
        },
        assignKindsFromPoints (context, data) {
            return incidentsApi.assignKindsFromPoints({
                data: data
            });
        },
        unassignKindsFromPoints (context, data) {
            return incidentsApi.unassignKindsFromPoints({
                data: data
            });
        }
    }
};
