export const ICONS = {
    CLOSE: 'mdi-close',
    PDF: 'mdi-file-pdf',
    XLSX: 'mdi-table-arrow-down',
    CHECK_CIRCLE: 'mdi-check-circle-outline',
    ERROR_CIRCLE: 'mdi-alert-circle-outline',
    MAP: 'mdi-map-marker',
    MAP_OFF: 'mdi-map-marker-off',
    INFORMATION: 'mdi-information-outline',
    EXCLAMATION_THICK: 'mdi-exclamation-thick',
    DETAILS: 'mdi-eye-outline',
    DELETE: 'mdi-trash-can-outline',
    DELETE_CONFIRM: 'mdi-delete-forever',
    EDIT: 'mdi-pencil-outline',
    QR_CODE: 'mdi-qrcode-scan',
    GEOLOCATION: 'mdi-earth',
    DOWNLOAD: 'mdi-download-outline',
    EXPORT: 'mdi-application-export',
    DETAILS_OFF: 'mdi-eye-off-outline',
    PLUS: 'mdi-plus',
    SINGLE_LEFT: 'mdi-chevron-left',
    SINGLE_RIGHT: 'mdi-chevron-right',
    DOUBLE_LEFT: 'mdi-chevron-double-left',
    DOUBLE_RIGHT: 'mdi-chevron-double-right',
    CLOCK: 'mdi-clock-time-four-outline',
    SORT: 'mdi-sort',
    SECURITY: 'mdi-security',
    FILE: 'mdi-file-image',
    ALERT: 'mdi-alert-outline',
    INCIDENTS: 'mdi-alert-outline',
    CLEAN_FILTER: 'mdi-filter-remove-outline',
    ADD_FILTER: 'mdi-filter-plus-outline',
    TABLE_FILTER: 'mdi-table-filter',
    SAVE: 'mdi-content-save',
    SAVE_PLUS: 'mdi-content-save-plus',
    SINGLE_UP: 'mdi-chevron-up',
    SINGLE_DOWN: 'mdi-chevron-down',
    SEARCH: 'mdi-magnify',
    CALENDAR: 'mdi-calendar',
    MENU: 'mdi-menu',
    LOGOUT: 'mdi-logout',
    HOME: 'mdi-home-outline',
    REPORTS: 'mdi-chart-multiple',
    CHECKBOX: 'mdi-checkbox-outline',
    INSPECTIONS: 'mdi-checkbox-marked-outline',
    ACCOUNT_GROUP: 'mdi-account-group-outline',
    ACCOUNT: 'mdi-account-outline',
    CHECKPOINT: 'mdi-map-marker-radius-outline',
    POINT_ADD: 'mdi-map-marker-plus-outline',
    POINT: 'mdi-map-marker-outline',
    ACCOUNT_SWITCH: 'mdi-account-switch-outline',
    CALENDAR_ALERT: 'mdi-calendar-alert',
    CUBE: 'mdi-cube-unfolded',
    CONFIGURATION: 'mdi-table-cog',
    SETTINGS: 'mdi-cog-outline',
    CALENDAR_CHECK: 'mdi-calendar-check-outline',
    REFRESH: 'mdi-refresh',
    ADD: 'mdi-shape-rectangle-plus',
    ADD_SHORTCUT: 'mdi-star-plus-outline',
    REPEAT: 'mdi-repeat',
    IMPORT: 'mdi-application-import',
    CHECK: 'mdi-check',
    ZOOM: 'mdi-magnify-plus-outline',
    DRAG: 'mdi-drag',
    COPY: 'mdi-content-copy',
    ARCHIVE_DOWN: 'mdi-archive-arrow-down-outline',
    ARCHIVE_UP: 'mdi-archive-arrow-up-outline',
    ARCHIVE: 'mdi-archive-outline',
    ARCHIVE_OUT: 'mdi-checkbox-blank-off-outline',
    MINUS: 'mdi-minus',
    ADD_EMPLOYEE: 'mdi-account-plus',
    PERCENTAGE: 'mdi-percent-outline',
    CYCLIC_CHECK: 'mdi-recycle-variant',
    TRACKS: 'mdi-timeline-check-outline',
    CELLPHONE: 'mdi-cellphone',
    ELEMENT: 'mdi-tag-outline',
    ADD_QUESTION: 'mdi-comment-plus-outline',
    AUTOCOMPLETE: 'mdi-auto-fix'
};
