import sitesApi from '@/utils/api/sites';
import siteGroupsApi from '@/utils/api/siteGroups';
import SitesGroup from '@/models/customers/sitesGroup';

export default {
    namespaced: true,
    state: () => ({
        rawSiteGroups: [],
        totalSitesGroupsAmount: null,
        rawSites: [],
        totalSitesAmount: null
    }),
    getters: {
        sites: state => state.rawSites.map(el => ({
            value: el.id,
            text: el.name
        })),
        siteGroups: state => state.rawSiteGroups.map(el => el.getFilterableData())
    },
    mutations: {
        SET_RAW_SITE_GROUPS (state, payload) {
            state.rawSiteGroups = payload;
        },
        SET_RAW_SITES (state, payload) {
            state.rawSites = payload;
        },
        SET_TOTAL_SITES_AMOUNT (state, payload) {
            state.totalSitesAmount = payload;
        },
        SET_TOTAL_SITES_GROUPS_AMOUNT (state, payload) {
            state.totalSitesGroupsAmount = payload;
        }
    },
    actions: {
        getSiteGroups ({ commit }, additionalParams = null) {
            commit('SET_RAW_SITE_GROUPS', []);

            const params = {
                ordering: 'name'
            };

            if (additionalParams) {
                Object.keys(additionalParams).forEach(k => {
                    params[k] = additionalParams[k];
                });
            }

            return siteGroupsApi.getSiteGroups(params)
                .then(({ data }) => {
                    commit('SET_RAW_SITE_GROUPS', data.results.map(el => {
                        const result = {
                            ...el
                        };
                        return new SitesGroup(result);
                    }));

                    if (data.count) {
                        commit('SET_TOTAL_SITES_GROUPS_AMOUNT', data.count);
                    }
                });
        },
        getSites ({ commit }, additionalParams = null) {
            commit('SET_RAW_SITES', []);
            commit('SET_TOTAL_SITES_AMOUNT', null);

            const params = {
                'stats[total]': 'count',
                sort: 'name'
            };

            if (additionalParams) {
                Object.keys(additionalParams).forEach(k => {
                    params[k] = additionalParams[k];
                });
            }

            return sitesApi.getSites(params)
                .then(({ data }) => {
                    commit('SET_RAW_SITES', data.data.map(el => ({
                        id: el.id,
                        ...el.attributes
                    })));
                    if (data.meta.stats) {
                        commit('SET_TOTAL_SITES_AMOUNT', data.meta.stats.total.count);
                    }
                    return data;
                });
        },
        getSitesLazyLoading ({ state, commit }, additionalParams = null) {
            const params = {
                'stats[total]': 'count',
                sort: 'name'
            };

            if (additionalParams) {
                Object.keys(additionalParams).forEach(k => {
                    params[k] = additionalParams[k];
                });
            }

            return sitesApi.getSites(params)
                .then(({ data }) => {
                    const result = [...state.rawSites].concat(data.data.map(el => ({
                        id: el.id,
                        ...el.attributes
                    })));

                    commit('SET_RAW_SITES', result);
                    if (data.meta.stats) {
                        commit('SET_TOTAL_SITES_AMOUNT', data.meta.stats.total.count);
                    }
                });
        },
        importSites (context, file) {
            return sitesApi.importSites({
                data: {
                    file: file
                }
            });
        }
    }
};
