import { TYPES } from '@/constants/questionTypes';
import { RELATIONSHIPS } from '@/helpers/jsonapiHelpers';

class TemplateQuestion {
    constructor (params) {
        this.sequence = (params?.sequence !== undefined) ? params?.sequence : null;
        this.type = (params?.type !== undefined) ? params?.type : null;
        this.typeName = params?.typeName || '';
        this.question = params?.question || '';
        this.id = (params?.id !== undefined) ? params?.id : null;
        this.parameters = params?.parameters || [];
        this.condition = (params?.condition !== undefined) ? params?.condition : null;
        this.smsContent = params?.smsContent || '';
        this.incident = params?.incident || null;
        this.incidentName = params?.incidentName || '';
        this.repeated = params?.repeated || false;
        this.required = params?.required || false;
        this.notApplicable = params?.notApplicable || false;
        this.specialAttention = params?.specialAttention || null;
        this.includeInScanField = params?.includeInScanField || false;
        this.autocomplete = params?.autocomplete || false;
        this.random = params?.display_type === 'random' || false;
    }

    #formParams = {
        [TYPES.RATING]: {
            parameters: false,
            condition: true,
            incident: true,
            required: true,
            repeated: true,
            random: true,
            notApplicable: true,
            includeInScanField: false
        },
        [TYPES.PICTURES]: {
            parameters: false,
            condition: false,
            incident: true,
            required: true,
            repeated: true,
            random: true,
            notApplicable: true,
            includeInScanField: false
        },
        [TYPES.TEXT]: {
            parameters: false,
            condition: false,
            incident: false,
            required: true,
            repeated: true,
            random: true,
            notApplicable: true,
            includeInScanField: true,
            autocomplete: true
        },
        [TYPES.MULTIPLE_CHOICE]: {
            parameters: true,
            condition: true,
            incident: true,
            required: true,
            repeated: true,
            random: true,
            notApplicable: true,
            includeInScanField: false
        },
        [TYPES.PERCENT]: {
            parameters: false,
            condition: true,
            incident: true,
            required: true,
            repeated: true,
            random: true,
            notApplicable: true,
            includeInScanField: true
        },
        [TYPES.SINGLE_CHOICE]: {
            parameters: true,
            condition: false,
            incident: true,
            required: true,
            repeated: true,
            random: true,
            notApplicable: true,
            includeInScanField: false
        },
        [TYPES.YES_NO]: {
            parameters: false,
            condition: true,
            incident: true,
            required: true,
            repeated: true,
            random: true,
            notApplicable: true,
            includeInScanField: false
        },
        [TYPES.QR_CODE]: {
            parameters: false,
            condition: false,
            incident: true,
            required: true,
            repeated: true,
            random: true,
            notApplicable: true,
            includeInScanField: false
        },
        [TYPES.VALUE]: {
            parameters: false,
            condition: true,
            incident: true,
            required: true,
            repeated: true,
            random: true,
            notApplicable: true,
            includeInScanField: true
        },
        [TYPES.SIGNATURE]: {
            parameters: false,
            condition: false,
            incident: true,
            required: true,
            repeated: true,
            random: true,
            notApplicable: true,
            includeInScanField: false
        },
        [TYPES.DATE]: {
            parameters: false,
            condition: false,
            incident: false,
            required: true,
            repeated: true,
            random: true,
            notApplicable: true,
            includeInScanField: false
        },
        [TYPES.TIME]: {
            parameters: false,
            condition: false,
            incident: false,
            required: true,
            repeated: true,
            random: true,
            notApplicable: true,
            includeInScanField: false
        },
        [TYPES.HEADER]: {
            parameters: false,
            condition: false,
            incident: false,
            required: false,
            repeated: false,
            random: true,
            notApplicable: false,
            includeInScanField: false
        },
        [TYPES.INFORMATION]: {
            parameters: false,
            condition: false,
            incident: false,
            required: false,
            repeated: false,
            random: true,
            notApplicable: false,
            includeInScanField: false
        }
    };

    #getBaseJSONStructure () {
        const result = {
            type: 'template_questions',
            attributes: {
                question: this.question,
                sequence: this.sequence,
                question_parameters: this.parameters,
                sms_content: this.smsContent,
                required: this.required,
                not_applicable: this.notApplicable,
                config: this.repeated,
                include_in_scan_field: this.includeInScanField,
                autocomplete: this.autocomplete,
                display_type: this.random ? 'random' : 'fixed'
            },
            relationships: {
                question_type: {
                    data: RELATIONSHIPS.CREATE.TEMPLATE_QUESTION(this.type)
                }
            }
        };
        if (this.condition !== null && this.condition !== '') {
            result.attributes.minimum_value = this.condition;
        }

        if (this.incident !== null && this.incident !== undefined) {
            result.relationships.incident_kind = {
                data: RELATIONSHIPS.CREATE.INCIDENT_TYPE(this.incident)
            };
        }

        return result;
    }

    getFormParams () {
        return this.#formParams[parseInt(this.type)] || {};
    }

    setDefaultValues () {
        this.parameters = [];
        this.condition = null;
        this.smsContent = '';
        this.incident = null;
        this.incidentName = '';
        this.repeated = false;
        this.required = true;
        this.notApplicable = false;
        this.includeInScanField = false;
        this.autocomplete = false;
        this.random = false;
    }

    parseData (params) {
        this.sequence = params.sequence || null;
        this.type = params.type || null;
        this.typeName = params.typeName || '';
        this.question = params.question || '';
        this.id = params.id || null;
        this.parameters = params.parameters || '';
        this.condition = params.condition || null;
        this.smsContent = params.smsContent || '';
        this.incident = params.incident || null;
        this.incidentName = params.incidentName || null;
        this.repeated = params.repeated || false;
        this.required = (params.required !== undefined) ? params.required : true;
        this.notApplicable = params.notApplicable || false;
        this.specialAttention = params.specialAttention || null;
        this.includeInScanField = params.includeInScanField || false;
        this.autocomplete = params.autocomplete || false;
        this.random = params.random || params.display_type === 'random' || false;
    }

    getDataForInclude () {
        const data = this.#getBaseJSONStructure();
        data['temp-id'] = this.id;

        return data;
    }

    getDataForIncludeUpdate () {
        const data = this.#getBaseJSONStructure();
        data.id = this.id;

        return data;
    }

    getDataForCreate (templateId) {
        const data = {
            data: this.#getBaseJSONStructure()
        };

        data.data.relationships.template = {
            data: RELATIONSHIPS.CREATE.TEMPLATE(templateId)
        };

        return data;
    }

    getDataForUpdate (templateId) {
        const data = this.getDataForCreate(templateId);

        data.data.id = this.id;

        return data;
    }
}

export default TemplateQuestion;
