import locationsApi from '@/utils/api/locations';

export default {
    namespaced: true,
    state: () => ({
        rawLocations: [],
        totalLocationsAmount: null
    }),
    getters: {
        locations: state => state.rawLocations.map(el => ({
            value: el.id,
            text: el.name
        })),
        locationsTypes: (state, _, rootState) => id => {
            if (state.rawLocations.length === 0) return [];

            const locationTypes = state.rawLocations.find(el => el.id === id)?.point_incident_kinds;
            const incidentTypes = rootState.incidents.incidentTypes;
            const commonTypes = locationTypes?.filter(type => incidentTypes.find(incType => +incType.id === type.incident_kind_id));

            return commonTypes?.map(el => ({
                value: el.incident_kind_id.toString(),
                text: el.incident_kind_name
            })) || [];
        },
        locationsInspectors: (state, _, rootState) => id => {
            if (state.rawLocations.length === 0) return [];

            const locationEmployees = state.rawLocations.find(el => el.id === id)?.point_inspectors;
            const employees = rootState.employees.rawResponsibleEmployees;
            const commonEmployees = locationEmployees?.filter(type => employees.find(employee => +employee.id === type.inspector_id));

            return commonEmployees?.map(el => ({
                value: el.inspector_id.toString(),
                text: el.inspector_name
            })) || [];
        },
        inspectors: state => id => state.rawLocations.find(el => el.id === id)?.point_inspectors
    },
    mutations: {
        SET_RAW_LOCATIONS (state, payload) {
            state.rawLocations = payload;
        },
        SET_TOTAL_LOCATIONS_AMOUNT (state, payload) {
            state.totalLocationsAmount = payload;
        }
    },
    actions: {
        getLocations ({ commit }, additionalParams = null) {
            commit('SET_RAW_LOCATIONS', []);
            commit('SET_TOTAL_LOCATIONS_AMOUNT', null);

            const params = {
                'stats[total]': 'count',
                sort: 'name'
            };

            if (additionalParams) {
                Object.keys(additionalParams).forEach(k => {
                    params[k] = additionalParams[k];
                });
            }

            return locationsApi.getLocations(params)
                .then(({ data }) => {
                    commit('SET_RAW_LOCATIONS', data.data.map(el => ({
                        id: el.id,
                        ...el.attributes
                    })));
                    if (data.meta.stats) {
                        commit('SET_TOTAL_LOCATIONS_AMOUNT', data.meta.stats.total.count);
                    }
                    return data;
                });
        },
        getLocationsLazyLoading ({ state, commit }, additionalParams = null) {
            const params = {
                'stats[total]': 'count',
                sort: 'name'
            };

            if (additionalParams) {
                Object.keys(additionalParams).forEach(k => {
                    params[k] = additionalParams[k];
                });
            }

            return locationsApi.getLocations(params)
                .then(({ data }) => {
                    const result = [...state.rawLocations].concat(data.data.map(el => ({
                        id: el.id,
                        ...el.attributes
                    })));

                    commit('SET_RAW_LOCATIONS', result);
                    if (data.meta.stats) {
                        commit('SET_TOTAL_LOCATIONS_AMOUNT', data.meta.stats.total.count);
                    }
                });
        },
        importLocations (context, file) {
            return locationsApi.importLocations({
                data: {
                    file: file
                }
            });
        }
    }
};
