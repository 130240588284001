import customersApi from '@/utils/api/customers';
import sitesApi from '@/utils/api/sites';
import locationsApi from '@/utils/api/locations';

export default {
    namespaced: true,
    state: () => ({
        customers: [],
        totalCustomersAmount: null,
        sitesPerCustomers: [],
        locationsPerSite: [],
        focusedField: null,
        update: false,
        pointCreationAvailable: null
    }),
    getters: {
        listingCustomers: state => state.customers.map(el => ({
            value: el.id,
            text: el.name,
            places_count: (el.places_amount ? parseInt(el.places_amount, 10) : 0),
            points_count: (el.points_amount ? parseInt(el.points_amount, 10) : 0)
        }))
    },
    mutations: {
        SET_CUSTOMERS (state, payload) {
            state.customers = payload;
        },
        SET_UPDATE (state, payload) {
            state.update = payload;
        },
        SET_TOTAL_CUSTOMERS_AMOUNT (state, payload) {
            state.totalCustomersAmount = payload;
        },
        SET_SITES_PER_CUSTOMERS (state, payload) {
            const _result = [];

            if (payload.length > 0) {
                payload.map(el => {
                    const _cityName = el.attributes.city_name || '';
                    const _zipCode = el.attributes.zip_code || '';

                    _result.push({
                        id: el.id,
                        ...el.attributes,
                        city: `${_zipCode} ${_cityName}`
                    });
                });
            }

            state.sitesPerCustomers = _result;
        },
        SET_LOCATIONS_PER_SITE (state, payload) {
            const result = payload.map(el => {
                return {
                    id: el.id,
                    ...el.attributes
                };
            });
            state.locationsPerSite = result;
        },
        SET_FOCUSED_FIELD (state, payload) {
            state.focusedField = payload;
        },
        SET_POINT_CREATION_AVAILABLE (state, payload) {
            state.pointCreationAvailable = payload;
        }
    },
    actions: {
        getCustomers ({ commit }, additionalParams = null) {
            commit('SET_CUSTOMERS', []);
            commit('SET_TOTAL_CUSTOMERS_AMOUNT', null);
            const params = {
                sort: 'name',
                'stats[total]': 'count'
            };

            if (additionalParams) {
                Object.keys(additionalParams).forEach(k => {
                    params[k] = additionalParams[k];
                });
            }

            return customersApi.getCustomers(params)
                .then(({ data }) => {
                    commit('SET_CUSTOMERS', data.data.map(el => {
                        const _city = el.attributes.city_name || '';
                        const _street = el.attributes.street_and_number || '';
                        const _places = el.attributes.places_amount;
                        const _points = el.attributes.points_amount;
                        return {
                            id: el.id,
                            ...el.attributes,
                            address: `${_city} ${_street}`,
                            places_points: `${_places}/${_points}`
                        };
                    }));
                    if (data.meta.stats) {
                        commit('SET_TOTAL_CUSTOMERS_AMOUNT', data.meta.stats.total.count);
                    }
                    commit('SET_POINT_CREATION_AVAILABLE', data.meta.point_creation_available);
                });
        },
        getCustomersLazyLoading ({ state, commit }, additionalParams = null) {
            const params = {
                sort: 'name',
                'stats[total]': 'count'
            };

            if (additionalParams) {
                Object.keys(additionalParams).forEach(k => {
                    params[k] = additionalParams[k];
                });
            }

            return customersApi.getCustomers(params)
                .then(({ data }) => {
                    const result = [...state.customers].concat(data.data.map(el => {
                        const _city = el.attributes.city_name || '';
                        const _street = el.attributes.street_and_number || '';
                        const _places = el.attributes.places_amount;
                        const _points = el.attributes.points_amount;
                        return {
                            id: el.id,
                            ...el.attributes,
                            address: `${_city} ${_street}`,
                            places_points: `${_places}/${_points}`
                        };
                    }));

                    commit('SET_CUSTOMERS', result);
                    if (data.meta.stats) {
                        commit('SET_TOTAL_CUSTOMERS_AMOUNT', data.meta.stats.total.count);
                    }
                });
        },
        getSitesPerCustomer ({ commit }, payload) {
            let pageNumber = 1;
            let id;
            if (typeof (payload) !== 'object') {
                id = payload;
            } else {
                pageNumber = payload.pageNumber;
                id = payload.id;
            }
            return sitesApi.getSites({
                'filter[customer_id]': id,
                'page[number]': pageNumber || 1
            })
                .then(({ data }) => {
                    commit('SET_SITES_PER_CUSTOMERS', data.data);
                });
        },
        refreshPointsGeolocation (context) {
            return locationsApi.refreshGeolocation();
        },
        getLocationsPerSite ({ commit }, payload) {
            let pageNumber = 1;
            let id;
            if (typeof (payload) !== 'object') {
                id = payload;
            } else {
                pageNumber = payload.pageNumber;
                id = payload.id;
            }
            return locationsApi.getLocations({
                'filter[place_id]': id,
                'page[number]': pageNumber || 1
            })
                .then(({ data }) => {
                    commit('SET_LOCATIONS_PER_SITE', data.data);
                });
        },
        importCustomers (context, file) {
            return customersApi.importCustomers({
                data: {
                    file: file
                }
            });
        }
    }
};
