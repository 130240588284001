<template xmlns:v-slot="https://www.w3.org/1999/XSL/Transform">
    <div>
        <v-dialog v-model="visibility"
                  @click:outside="closeOutside"
                  v-on="$listeners"
                  v-bind="$attrs">
            <template v-slot:activator="{ on, attrs }" v-if="withActivator">
                <v-btn color="secondary"
                       v-bind="attrs"
                       v-on="on">
                    {{ buttonTitle }}
                </v-btn>
            </template>
            <v-card :loading="loading">
                <v-card-title :class="titleClass">
                    <v-layout v-bind="titleJustify"
                              wrap>
                        <v-flex v-if="modalTitle">
                            <slot name="title">
                                <span class="headline">{{ modalTitle }}</span>
                            </slot>
                        </v-flex>
                        <v-flex shrink>
                            <v-icon v-if="closeIcon" @click="close">{{ ICONS.CLOSE }}</v-icon>
                            <slot name="actions"></slot>
                        </v-flex>
                        <v-flex xs12>
                            <slot name="subtitle"></slot>
                        </v-flex>
                    </v-layout>
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text :class="[{'pb-0': dense || fullHeight}, fullWidth ? 'px-0' : 'px-5']" >
                    <v-container fluid class="pa-0" :class="[fullHeight ? 'pt-0' : 'pt-3']">
                        <slot name="content"></slot>
                    </v-container>
                </v-card-text>

                <v-divider v-if="hasFooter" />

                <v-card-actions v-if="hasFooter" class="py-3 px-5">
                    <v-spacer></v-spacer>
                    <slot name="footer"></slot>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { ICONS } from '@/constants/icons';

export default {
    name: 'Modal',
    props: {
        buttonTitle: {
            type: String,
            required: false
        },
        modalTitle: {
            type: String,
            'default': ''
        },
        titlePadding: {
            type: Boolean,
            'default': false
        },
        dialog: {
            type: Boolean,
            required: true
        },
        withActivator: {
            type: Boolean,
            'default': false
        },
        dense: {
            type: Boolean,
            'default': false
        },
        fullWidth: {
            type: Boolean,
            'default': false
        },
        fullHeight: {
            type: Boolean,
            'default': false
        },
        closeIcon: {
            type: Boolean,
            'default': true
        },
        loading: {
            type: Boolean,
            'default': false
        }
    },
    data: () => ({
        visibility: false,
        ICONS
    }),
    computed: {
        titleClass () {
            return (this.modalTitle || this.closeIcon || this.titlePadding) ? 'px-5' : 'pa-0';
        },
        titleJustify () {
            return this.modalTitle ? {
                'justify-space-between': true
            } : {
                'justify-end': true
            };
        },
        hasFooter () {
            return !!this.$slots.footer;
        }
    },
    watch: {
        dialog: {
            handler (val) {
                this.visibility = val;
            },
            immediate: true
        },
        visibility: {
            handler (val) {
                this.$emit('visibility', val);
            }
        }
    },
    methods: {
        close () {
            this.$emit('close');
        },
        closeOutside () {
            if (!this.$attrs.persistent) {
                this.close();
            }
        }
    }
};
</script>

<style scoped lang="scss">
    ::v-deep {
        .v-card__actions > .v-btn.v-btn + .v-btn {
            margin-left: 12px!important;
        }
    }
</style>
