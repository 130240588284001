import KmsHttpService from '@/servises/kmsHttpService';
import { getV2Endpoint } from '@/utils/apiV2Endpoints';

export default {
    getInspections: (params) => {
        const endpoint = getV2Endpoint({ 'group': 'inspections', 'endpoint': 'list' }, null);
        return KmsHttpService.get(endpoint, params);
    },
    deleteInspection: (id) => {
        const endpoint = getV2Endpoint({ 'group': 'inspections', 'endpoint': 'manage' }, { id: id });
        return KmsHttpService.delete(endpoint, null);
    },
    getInspectionTemplates: (params) => {
        return KmsHttpService.get(getV2Endpoint('inspection_templates'), params);
    },
    getInspectionQuestions: (id) => {
        return KmsHttpService.get(getV2Endpoint('inspection_questions', { id: id }));
    },
    answerControlFields: (params) => {
        return KmsHttpService.post(getV2Endpoint('control_fields/bulk_create'), params);
    },
    getInspectionTemplatesByPoint: (params) => {
        const endpoint = getV2Endpoint({ 'group': 'inspections', 'endpoint': 'templates_by_point' });
        return KmsHttpService.get(endpoint, params);
    },
    getPdfReport: (id) => {
        return getV2Endpoint({ 'group': 'inspections', 'endpoint': 'get_pdf_report' }, { id: id });
    },
    sendReport: (id, params) => {
        return KmsHttpService.post(getV2Endpoint({ 'group': 'inspections', 'endpoint': 'send_report' }, { id: id }), params);
    },
    getQuestionSuggestions: (params) => {
        return KmsHttpService.get(getV2Endpoint('incident_question_suggestions'), params);
    },
    getPrefillAnswers: (params) => {
        return KmsHttpService.get(getV2Endpoint('incident_prefill_answers'), params);
    }
};
